import React from 'react'
import GooglePlay from '../components/GooglePlay'
import AppleStore from '../components/AppleStore'
import Layout from "../components/layout"
const NotFoundPage = () => (
  <Layout>
      <div className="pa0 ma0 frendli-blue-bkg center">
            <div className="frendli-oh-oh-bkg-img pa0 ma0 w-100 center">
                <div class="w-100 mt0 pt3 tc center">
                    <h1 class="shawdow-white w-100 f2-l pa2 center tracked lh-title ma0 pt4">
                    Ooops... nothing to see here
                    </h1>
                </div>
            </div>
            <section class="w-100 signup-section frendli-blue-bkg tracked mt0 pt0 pb5 tc center ">
                <div class="w-80 mt0 pt0 tc center mw7">
                    <div className="w-100 tc center mt4 mt1-ns lh-copy">
                        <a href="/download/" className="b f4 black pl1 pr1 pl5-l pr4-l fw4 shawdow-white">
                            Sign Up For Frendli Today!
                        </a>
                        <div className="flex justify-center w-100-ns w-90 center">
                            <div className="center dn db-ns w-25 pa2" />
                                <GooglePlay />
                                <AppleStore />
                            <div className="center dn db-ns w-25 pa2" />
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </Layout>
)

export default NotFoundPage
